import * as React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

function About() {
  return (
    <Layout>
      <Wrapper>
        <Header>
          <h1>Welcome to my Dev Blog!</h1>
        </Header>
        <p>여러분, 안녕하세요! 👋</p>
        <p>&nbsp;</p>
        <p>
          DaleSeo는 제가 소프트웨어 개발을 하면서 배운 내용을 최대한 많은 분들과
          공유하기 위해서 만든 개인 블로그입니다. 🧑‍💻
        </p>
        <p>&nbsp;</p>
        <p>
          2016년부터 블로그를 시작해서 습관처럼 매주 한 두개의 포스팅을 올렸는데
          2022년 한 해 동안 제 블로그의 사용자가 110만명을 넘겨 깜짝 놀랐네요 💯
        </p>
        <p>&nbsp;</p>
        <Img
          src="https://res.cloudinary.com/daleseo/image/upload/v1672672905/analytics-2022.png"
          alt="analytics 2022"
        />
        <p>&nbsp;</p>
        <p>
          전문적으로 기술 관련 글을 쓰는 사람이 아니라서 부족한 부분이 많은데
          이렇게 많이 제 블로그를 찾아주셔서 무엇보다 감사하다는 말씀을 먼저
          드리고 싶습니다. 🙇
        </p>
        <p>&nbsp;</p>
        <p>
          혹시 제 블로그를 보시다가 궁금한 부분이 생기시면 언제든지
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/6TwzdnW6ze"
          >
            디스코드 커뮤니티
          </a>
          에 오셔서 질문주시면 답변 드리겠습니다. 그 밖에 오타나 맞춤법 등 제
          글에 고쳐야할 부분에 대해서도 디스코드 커뮤니티를 통해 알려주시면
          감사하겠습니다. 여러분의 피드백은 블로그의 컨텐츠를 개선하는데 항상 큰
          도움이 됩니다. 👍
        </p>
        <p>&nbsp;</p>
        <p>
          P.S. 최근에 큰 마음먹고
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://youtube.com/@DaleSeo"
          >
            유튜브 채널
          </a>
          을 하나 시작하였습니다. 텍스트만으로는 설명이 어려운 내용은 앞으로
          영상으로 만들어 올릴테니 많은 관심 부탁드리겠습니다. 🙏
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.main`
  min-width: 300px;
  max-width: 700px;
  margin: var(--spacing-8) auto;
  justify-content: space-evenly;
  line-height: 1.6;

  a {
    text-decoration: none;
    transition: box-shadow 400ms ease 0s;
    box-shadow: 0px 0px 0px var(--color-primary);
    color: var(--color-primary);
    font-weight: var(--font-weight-regular);

    &:hover {
      transition: box-shadow 100ms ease 0s;
      box-shadow: 0px 2px 0px var(--color-primary);
    }
  }
`;

const Header = styled.header`
  padding: 64px 32px;
  text-align: center;
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
  padding: var(--spacing-4);
`;

export default About;
